import DateRangePicker from '../plugins/vanilla-datetimerange-picker'

export default (Alpine) => {
  Alpine.data(
    'dateRangeComponent',
    ({
      name,
      state,
      alwaysShowCalendars,
      autoApply,
      linkedCalendars,
      startDate,
      endDate,
      maxDate,
      minDate,
      timePicker,
      timePickerIncrement,
      displayFormat,
      applyLabel,
      cancelLabel,
      fromLabel,
      toLabel,
      customRangeLabel,
      sunday,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      january,
      february,
      march,
      april,
      may,
      june,
      july,
      august,
      september,
      october,
      november,
      december,
      firstDay,
      today,
      yesterday,
      last_7_days,
      last_30_days,
      this_month,
      last_month,
      this_year,
      last_year,
      handleValueChangeUsing,
    }) => {
      return {
        dateRangePicker: null,
        state,
        init() {
          const dayjs = window.dayjs

          let finalStartDate
          let finalEndDate

          if (this.state != null) {
            const dates = this.state.split(' - ')
            if (dates.length === 2) {
              finalStartDate = dates[0]
              finalEndDate = dates[1]
            }
          }
          else {
            finalStartDate = startDate ? dayjs(startDate) : dayjs()
            finalEndDate = endDate ? dayjs(endDate) : dayjs()
          }

          if (!(dayjs(finalStartDate).isValid() && dayjs(finalEndDate).isValid())) {
            finalStartDate = dayjs()
            finalEndDate = dayjs()
          }

          const drp = new DateRangePicker(
            this.$refs.daterange,
            {
              name,
              alwaysShowCalendars,
              autoApply,
              linkedCalendars,
              showDropdowns: true,
              autoUpdateInput: false,
              startDate: finalStartDate,
              endDate: finalEndDate,
              maxDate: maxDate != null ? dayjs(maxDate) : null,
              minDate: minDate != null ? dayjs(minDate) : null,
              timePicker,
              timePickerIncrement,
              handleApplyUsing: handleValueChangeUsing,
              locale: {
                format: displayFormat,
                separator: ' - ',
                applyLabel,
                cancelLabel,
                fromLabel,
                toLabel,
                customRangeLabel,
                weekLabel: 'W',
                daysOfWeek: [
                  sunday,
                  monday,
                  tuesday,
                  wednesday,
                  thursday,
                  friday,
                  saturday,
                ],
                monthNames: [
                  january,
                  february,
                  march,
                  april,
                  may,
                  june,
                  july,
                  august,
                  september,
                  october,
                  november,
                  december,
                ],
                firstDay,
              },
              ranges: {
                [today]: [dayjs(), dayjs()],
                [yesterday]: [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
                [last_7_days]: [dayjs().subtract(7, 'days'), dayjs().subtract(1, 'days')],
                [last_30_days]: [dayjs().subtract(30, 'days'), dayjs().subtract(1, 'days')],
                [this_month]: [dayjs().startOf('month'), dayjs().endOf('month')],
                [last_month]: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                [this_year]: [dayjs().startOf('year'), dayjs().endOf('year')],
                [last_year]: [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
              },
            }, (start, end) => {
              handleValueChangeUsing(`${start.format(displayFormat)} - ${end.format(displayFormat)}`, name)
            })
          this.dateRangePicker = this.$refs.daterange.dataset.daterangepicker
          // if (this.state != null) {
          //   const dates = this.state.split(' - ')
          //   if (dates.length === 2 && this.dateRangePicker != null) {
          //     this.dateRangePicker.setStartDate(dates[0])
          //     this.dateRangePicker.setEndDate(dates[1])
          //   }
          // }
          this.$refs.daterange.value = this.state
          const parent = this
          this.$watch('state', (value) => {
            if (value == null) {
              value = ''
              parent.dateRangePicker.setStartDate(dayjs())
              parent.dateRangePicker.setEndDate(dayjs())
            }
            parent.$refs.daterange.value = value
          })
        },
      }
    },
  )
}
