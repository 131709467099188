import Alpine from 'alpinejs'
import Chart from 'chart.js/auto'
import Collapse from '@alpinejs/collapse'
import Focus from '@alpinejs/focus'
import Mousetrap from '@danharrin/alpine-mousetrap'
import Persist from '@alpinejs/persist'
import Tooltip from '@ryangjchandler/alpine-tooltip'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import dayjs from 'dayjs/esm'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import arraySupport from 'dayjs/plugin/arraySupport'
import isoWeek from 'dayjs/plugin/isoWeek'
import localeData from 'dayjs/plugin/localeData'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import { _adapters } from 'chart.js'
import NotificationsAlpinePlugin from '../../vendor/filament/notifications/dist/module.esm'
import FormsAlpinePlugin from '../../vendor/filament/forms/dist/module.esm'
import autocolors from './plugins/chartjs/autocolors'
import { FormatterAlpinePlugin, currency, number, percent } from './plugins/alpine/formatter'
import htmlLegend from './plugins/chartjs/html-legend'
import { calculatePercentage } from './utils/math'
import dateRangeComponent from './components/daterangepicker'
import dateRangeComapreComponent from './components/daterangecompare'

Chart.register(htmlLegend)
Chart.register(autocolors)
Chart.register(ChartDataLabels)

Alpine.plugin(Collapse)
Alpine.plugin(Focus)
Alpine.plugin(FormsAlpinePlugin)
Alpine.plugin(Mousetrap)
Alpine.plugin(NotificationsAlpinePlugin)
Alpine.plugin(Persist)
Alpine.plugin(Tooltip)
Alpine.plugin(FormatterAlpinePlugin)
Alpine.plugin(dateRangeComponent)
Alpine.plugin(dateRangeComapreComponent)

Alpine.store('sidebar', {
  isOpen: Alpine.$persist(true).as('isOpen'),

  collapsedGroups: Alpine.$persist(null).as('collapsedGroups'),

  groupIsCollapsed(group) {
    return this.collapsedGroups.includes(group)
  },

  collapseGroup(group) {
    if (this.collapsedGroups.includes(group))
      return

    this.collapsedGroups = this.collapsedGroups.concat(group)
  },

  toggleCollapsedGroup(group) {
    this.collapsedGroups = this.collapsedGroups.includes(group)
      ? this.collapsedGroups.filter(
        collapsedGroup => collapsedGroup !== group,
      )
      : this.collapsedGroups.concat(group)
  },

  close() {
    this.isOpen = false
  },

  open() {
    this.isOpen = true
  },
})

function setChartDefaults(theme) {
  if (theme === 'dark') {
    Chart.defaults.color = '#d1d5db' // gray-300
    Chart.defaults.scale.grid.color = '#374151' // gray-800
    Chart.defaults.borderColor = '#374151' // gray-800
  }

  if (theme === 'light') {
    Chart.defaults.color = '#6b7280' // gray-500
    Chart.defaults.scale.grid.color = '#f3f4f6' // gray-100
    Chart.defaults.borderColor = '#f3f4f6' // gray-100
  }
}

const prefersDarkColorSchemeMq = window.matchMedia('(prefers-color-scheme: dark)')

Alpine.store(
  'theme',
  prefersDarkColorSchemeMq.matches
    ? 'dark'
    : 'light',
)

window.addEventListener('dark-mode-toggled', (event) => {
  Alpine.store('theme', event.detail)
  setChartDefaults(Alpine.store('theme'))
})

prefersDarkColorSchemeMq
  .addEventListener('change', (event) => {
    Alpine.store('theme', event.matches ? 'dark' : 'light')
    setChartDefaults(Alpine.store('theme'))
  })

setChartDefaults(localStorage.getItem('theme') || Alpine.store('theme') || 'light')

const isRTL = document.documentElement.dir === 'rtl'

Chart.defaults.font.family = 'Ubuntu, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'

// Legend configuration
Chart.defaults.plugins.legend.display = false

// Datalabels configuration
Chart.defaults.plugins.datalabels.font.size = 12
Chart.defaults.plugins.datalabels.formatter = value => number(2).format(value)
Chart.defaults.plugins.datalabels.anchor = 'end'
Chart.defaults.plugins.datalabels.align = 'start'
Chart.defaults.plugins.datalabels.display = 'auto'
Chart.defaults.plugins.datalabels.textAlign = 'center'
Chart.defaults.plugins.datalabels.clamp = true

if (isRTL) {
  Chart.defaults.plugins.legend.rtl = true
  Chart.defaults.plugins.tooltip.rtl = true
}

// Tooltip configuration
Chart.defaults.plugins.tooltip.boxPadding = 8
Chart.defaults.plugins.tooltip.padding = 8
Chart.defaults.plugins.tooltip.caretPadding = 8
Chart.defaults.plugins.tooltip.caretSize = 12
Chart.defaults.plugins.tooltip.boxWidth = 12
Chart.defaults.plugins.tooltip.boxHeight = 12
Chart.defaults.plugins.tooltip.displayColors = false

// Pie chart configuration
Chart.defaults.datasets.pie.hoverOffset = 8

// Doughnut chart configuration
Chart.defaults.datasets.doughnut.hoverOffset = 8
Chart.defaults.layout.padding = 8

// Bar chart configuration
Chart.defaults.datasets.bar.borderWidth = 2
Chart.defaults.datasets.bar.maxBarThickness = 128

// Improves responsiveness
Chart.defaults.aspectRatio = 1
Chart.defaults.maintainAspectRatio = false

window.Metriko = {
  format: {
    number: value => number(2).format(value),
    currency: value => currency(0).format(value),
    percent: value => percent(2).format(value),
  },
  utils: {
    calculatePercentage,
  },
}

window.Alpine = Alpine
window.Chart = Chart
window.dayjs = dayjs

dayjs.extend(customParseFormat)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(arraySupport)
dayjs.extend(isoWeek)
dayjs.extend(localeData)
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(quarterOfYear)

const FORMATS = {
  datetime: 'MMM D, YYYY, h:mm:ss a',
  millisecond: 'h:mm:ss.SSS a',
  second: 'h:mm:ss a',
  minute: 'h:mm a',
  hour: 'hA',
  day: 'MMM D',
  week: 'll',
  month: 'MMM YYYY',
  quarter: '[Q]Q - YYYY',
  year: 'YYYY',
}

_adapters._date.override({
  // _id: 'dayjs', //DEBUG,
  formats: function formats() {
    return FORMATS
  },
  parse: function parse(value, format) {
    const valueType = typeof value
    if (value === null || valueType === 'undefined')
      return null

    if (valueType === 'string' && typeof format === 'string')
      return dayjs(value, format).isValid() ? dayjs(value, format).valueOf() : null

    else if (!(value instanceof dayjs))
      return dayjs(value).isValid() ? dayjs(value).valueOf() : null

    return null
  },
  format: function format(time, _format) {
    return dayjs(time).format(_format)
  },
  add: function add(time, amount, unit) {
    return dayjs(time).add(amount, unit).valueOf()
  },
  diff: function diff(max, min, unit) {
    return dayjs(max).diff(dayjs(min), unit)
  },
  startOf: function startOf(time, unit, weekday) {
    if (unit === 'isoWeek') {
      // Ensure that weekday has a valid format
      // const formattedWeekday
      const validatedWeekday = typeof weekday === 'number' && weekday > 0 && weekday < 7 ? weekday : 1
      return dayjs(time).isoWeekday(validatedWeekday).startOf('day').valueOf()
    }
    return dayjs(time).startOf(unit).valueOf()
  },
  endOf: function endOf(time, unit) {
    return dayjs(time).endOf(unit).valueOf()
  },
})

Alpine.start()
