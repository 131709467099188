export function calculatePercentage(value, context) {
  const hiddens = context.chart._hiddenIndices
  let total = 0
  const datapoints = context.dataset.data
  datapoints.forEach((val, i) => {
    if (hiddens[i] !== undefined) {
      if (!hiddens[i])
        total += val
    }
    else {
      total += val
    }
  })
  return value / total
}
