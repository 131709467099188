import { number, percent } from '../alpine/formatter'

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id)

  let listContainer = legendContainer.querySelector('ul')

  if (!listContainer) {
    listContainer = document.createElement('ul')
    listContainer.classList.add(
      'flex',
      'flex-row',
      'm-0',
      'p-0',
    )

    legendContainer.appendChild(listContainer)
  }

  return listContainer
}

const calculatePercentage = (value, chart, dataset) => {
  const hiddens = chart._hiddenIndices
  let total = 0
  const datapoints = dataset.data
  datapoints.forEach((val, i) => {
    if (hiddens[i] !== undefined) {
      if (!hiddens[i])
        total += val
    }
    else {
      total += val
    }
  })
  return value / total
}

export default {
  id: 'htmlLegend',

  defaults: {
    enabled: false,
    formatter: value => number(2).format(value),
  },

  afterUpdate(chart, _args, options) {
    if (!options.enabled)
      return

    const ul = getOrCreateLegendList(chart, options.containerID)

    // Remove old legend items
    while (ul.firstChild)
      ul.firstChild.remove()

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart)

    items.forEach((item) => {
      const li = document.createElement('li')
      li.classList.add(
        'items-center',
        'cursor-pointer',
        'flex',
        'flex-row',
        'ms-2',
        'py-1',
        'text-xs',
      )

      li.onclick = () => {
        const { type } = chart.config
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index)
        }
        else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex))
        }
        chart.update()
      }

      // Color box
      const boxSpan = document.createElement('span')
      boxSpan.classList.add(
        'inline-block',
        'h-5',
        'w-5',
        'me-2',
      )
      boxSpan.style.background = item.fillStyle
      boxSpan.style.borderColor = item.strokeStyle
      boxSpan.style.borderWidth = `${item.lineWidth}px`

      // Text
      const textContainer = document.createElement('p')
      textContainer.classList.add(
        'm-0',
        'p-0',
        'w-full',
        'flex',
        'justify-between',
        'text-gray-800',
        'dark:text-white',
      )
      // textContainer.style.color = item.fontColor
      textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

      const idx = chart.data.datasets.length > 1 ? item.index : 0

      const value = chart.data.datasets[idx].data[item.index]

      const formatter = chart.data.datasets[idx]?.datalabels?.formatter || options.formatter

      const text = document.createElement('span')
      text.textContent = item.text

      const valueText = document.createElement('span')

      const valuePercentage = calculatePercentage(value, chart, chart.data.datasets[idx])

      valueText.textContent = `${formatter(value)} (${percent(2).format(valuePercentage)})`

      textContainer.appendChild(text)
      textContainer.appendChild(valueText)

      li.appendChild(boxSpan)
      li.appendChild(textContainer)
      ul.appendChild(li)
    })
  },
}
