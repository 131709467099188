const cache = {
  number: new Map(),
  currency: new Map(),
  percent: new Map(),
}

export const format = (type, msd, config = {}) => {
  const maximumFractionDigits = Number.isInteger(msd) ? msd : 2
  const minimumFractionDigits = 0
  if (cache[type].has(maximumFractionDigits))
    return cache[type].get(maximumFractionDigits)
  cache[type].set(maximumFractionDigits, new Intl.NumberFormat('en-US', {
    ...config,
    minimumFractionDigits,
    maximumFractionDigits,
  }))
  return cache[type].get(maximumFractionDigits)
}

export const number = msd => format('number', msd)

export const currency = msd => format('currency', msd, { style: 'currency', currency: 'ILS' })

export const percent = msd => format('percent', msd, { style: 'percent' })

export function FormatterAlpinePlugin(Alpine) {
  Alpine.magic('number', () => (n, msd = 3) => number(msd).format(n))
  Alpine.magic('currency', () => (n, msd = 3) => currency(msd).format(n))
  Alpine.magic('percent', () => (n, msd = 3) => percent(msd).format(n))
}
