import tailwindColors from 'tailwindcss/colors'
import { hexParse, rgbString } from '@kurkle/color'

const COLORS = {
  ...tailwindColors,
  red: {
    50: '#fff0f0',
    100: '#ffe3e3',
    200: '#ffcacd',
    300: '#ff9fa4',
    400: '#ff6975',
    500: '#fe4c5e',
    600: '#ec1233',
    700: '#c7092a',
    800: '#a70a2b',
    900: '#8e0d2c',
    950: '#500111',
  },
  orange: {
    50: '#fff9ed',
    100: '#fff1d4',
    200: '#ffdfa8',
    300: '#ffc771',
    400: '#ffa438',
    500: '#fe9225',
    600: '#ef6b07',
    700: '#c65108',
    800: '#9d400f',
    900: '#7e3610',
    950: '#441906',
  },
  purple: {
    50: '#fbf4ff',
    100: '#f6e6ff',
    200: '#eed2ff',
    300: '#e2afff',
    400: '#d07cff',
    500: '#be4aff',
    600: '#ad26f7',
    700: '#9816dc',
    800: '#7f17b2',
    900: '#68148f',
    950: '#49006b',
  },
  magenta: {
    50: '#fff3fd',
    100: '#ffe6fd',
    200: '#ffccfb',
    300: '#ffa4f3',
    400: '#ff6eeb',
    500: '#fd38e7',
    600: '#e118c6',
    700: '#cb11ae',
    800: '#990f82',
    900: '#7d1269',
    950: '#540043',
  },
  blue: {
    50: '#eff5ff',
    100: '#dce7fd',
    200: '#c0d6fd',
    300: '#95bdfb',
    400: '#528ff7',
    500: '#3e75f3',
    600: '#2855e8',
    700: '#2041d5',
    800: '#2036ad',
    900: '#203388',
    950: '#182053',
  },
  turquoise: {
    50: '#effbfc',
    100: '#d6f2f7',
    200: '#b2e5ef',
    300: '#5cc5dd',
    400: '#40b3d0',
    500: '#2497b6',
    600: '#217a99',
    700: '#21637d',
    800: '#235267',
    900: '#214558',
    950: '#112c3b',
  },
}

// const theme = localStorage.getItem('theme') || 'light'
const STRATEGY_MONOCHROME = [
  700,
  600,
  500,
  400,
  300,
  200,
]

const BORDER_COLORS = [
  rgbString(hexParse(COLORS.orange[500])),
  rgbString(hexParse(COLORS.purple[700])),
  rgbString(hexParse(COLORS.magenta[700])),
  rgbString(hexParse(COLORS.red[500])),
  rgbString(hexParse(COLORS.blue[400])),
  rgbString(hexParse(COLORS.turquoise[300])),
  rgbString(hexParse(COLORS.orange[700])),
  rgbString(hexParse(COLORS.purple[500])),
  rgbString(hexParse(COLORS.magenta[500])),
  rgbString(hexParse(COLORS.red[400])),
  rgbString(hexParse(COLORS.blue[600])),
  rgbString(hexParse(COLORS.turquoise[500])),
]

// Border colors with 50% transparency
const BACKGROUND_COLORS = /* #__PURE__ */ BORDER_COLORS.map(color => color.replace('rgb(', 'rgba(').replace(')', ', 0.8)'))

function getBorderColor(i, colorOptions) {
  if (colorOptions.strategy === 'monochrome')
    return rgbString(hexParse(getMonochromeColor(i, colorOptions)))

  return BORDER_COLORS[(i + colorOptions.offset) % BORDER_COLORS.length]
}

function getBackgroundColor(i, colorOptions) {
  if (colorOptions.strategy === 'monochrome')
    return rgbString({ ...hexParse(getMonochromeColor(i, colorOptions)), a: 200 })

  return BACKGROUND_COLORS[(i + colorOptions.offset) % BACKGROUND_COLORS.length]
}

function getMonochromeColor(i, colorOptions) {
  return COLORS[colorOptions.baseTailwindColor][STRATEGY_MONOCHROME[i % STRATEGY_MONOCHROME.length]]
}

function colorizeDefaultDataset(dataset, i, colors) {
  dataset.borderColor = getBorderColor(i, colors)
  dataset.backgroundColor = getBackgroundColor(i, colors)

  return ++i
}

function colorizeDefaultData(dataset, i, colors) {
  let curr = i
  dataset.borderColor = dataset.data.map(() => getBorderColor(curr++, colors))
  dataset.backgroundColor = dataset.data.map(() => getBackgroundColor(i++, colors))
  return ++i
}

function colorizeDoughnutDataset(dataset, i, colors) {
  dataset.backgroundColor = dataset.data.map(() => getBorderColor(i++, colors))

  return i
}

function colorizePolarAreaDataset(dataset, i, colors) {
  dataset.backgroundColor = dataset.data.map(() => getBackgroundColor(i++, colors))

  return i
}

function getColorizer(chart, options) {
  let i = 0

  return (dataset, datasetIndex) => {
    const controller = chart.getDatasetMeta(datasetIndex).controller

    if (['DoughnutController'].includes(controller.constructor.name))
      i = colorizeDoughnutDataset(dataset, i, options.colors)

    else if (controller.constructor.name === 'PolarAreaController')
      i = colorizePolarAreaDataset(dataset, i, options.colors)

    else if (controller && options.mode === 'data')
      i = colorizeDefaultData(dataset, i, options.colors)

    else if (controller)
      i = colorizeDefaultDataset(dataset, i, options.colors)
  }
}

// function containsColorsDefinitions(descriptors) {
//   let k

//   for (k in descriptors) {
//     if (descriptors[k].borderColor || descriptors[k].backgroundColor)
//       return true
//   }

//   return false
// }

// function containsColorsDefinition(descriptor) {
//   return descriptor && (descriptor.borderColor || descriptor.backgroundColor)
// }

export default {
  id: 'autocolors',

  defaults: {
    enabled: true,
    forceOverride: false,
    mode: 'dataset',
    colors: {
      strategy: 'default',
      baseTailwindColor: 'blue',
      offset: 0,
    },
  },

  beforeLayout(chart, _args, options) {
    if (!options.enabled)
      return

    const {
      data: { datasets },
      // options: chartOptions,
    } = chart.config
    // const { elements } = chartOptions

    // if (!options.forceOverride && (containsColorsDefinitions(datasets) || containsColorsDefinition(chartOptions) || (elements && containsColorsDefinitions(elements))))
    //   return

    const colorizer = getColorizer(chart, options)

    datasets.forEach(colorizer)
  },
}
